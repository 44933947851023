/**
 * Flatpickr Plugin
 * "A lightweight and powerful datetime picker"
 *
 * Vue component : https://github.com/ankurk91/vue-flatpickr-component
 * Doc: https://flatpickr.js.org/
 */
import Vue from 'vue';
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

Vue.component('FlatPickr', FlatPickr);
