import Vue from 'vue';
import Vuesax from 'vuesax';
import { VueHammer } from 'vue2-hammer';
import CKEditor from 'ckeditor4-vue';
import VeeValidate from 'vee-validate';
import Toasted from 'vue-toasted';
import App from './App.vue';

// Vuesax Component Framework
import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css';

// axios
import axios from './axios';

// Theme Configurations
import '../themeConfig';
import './assets/css/variables.css';

// Globally Registered Components
import './globalComponents';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// Plugins
import './plugins/flatPicker';
import './plugins/vue-toastification';
import urlBuilder from './plugins/urlBuilder';
import parseError from './plugins/parseError';
import download from './plugins/download';

// Vuejs - Vue wrapper for hammerjs

// Filtres
import '@/filters/normalizeStatus';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import auth from './auth';

// Vuesax
Vue.use(Vuesax);
Vue.prototype.$http = axios;
Vue.prototype.$urlBuilder = urlBuilder;
Vue.prototype.$parseError = parseError;
Vue.prototype.$download = download;
Vue.use(VueHammer);

Vue.use(CKEditor);
Vue.use(VeeValidate);
Vue.use(Toasted);
// Check the users auth status when the app starts
auth.checkAuth();
// Feather font icon
require('./assets/css/iconfont.css');

Vue.prototype.$annoncesMax = 30;
Vue.prototype.$commandesCPMax = 5;
Vue.prototype.$limitPopulation = 200000;
Vue.prototype.$limitPopulationBonus = 250000;
Vue.prototype.$tolerancePopulation = 20;
Vue.prototype.$commandesMax = 3;
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
