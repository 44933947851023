import Vue from 'vue';
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue';
import VxCard from '@/components/vx-card/VxCard.vue';
import VxList from '@/components/vx-list/VxList.vue';
import VxBreadcrumb from '@/layouts/components/VxBreadcrumb.vue';
import FeatherIcon from '@/components/FeatherIcon.vue';
import VxInputGroup from '@/components/vx-input-group/VxInputGroup.vue';

// v-select component
import vSelect from 'vue-select';

Vue.component('VxTooltip', VxTooltip);
Vue.component('VxCard', VxCard);
Vue.component('VxList', VxList);
Vue.component('VxBreadcrumb', VxBreadcrumb);
Vue.component('FeatherIcon', FeatherIcon);
Vue.component('VxInputGroup', VxInputGroup);

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1',
      },
    }),
  },
  OpenIndicator: {
    render: (createElement) => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5',
      },
    }),
  },
});

Vue.component(vSelect);
