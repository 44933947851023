import axios from 'axios';

const baseURL = process.env.VUE_APP_BASE_URL;

const instance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const refreshToken = localStorage.getItem('refresh_token');
    const originalConfig = config;

    if (response && response.status === 401 && refreshToken && !originalConfig.retry) {
      try {
        // To avoid an infinite loop
        originalConfig.retry = true;

        // Get and set the new Token with the refresh token
        const { data } = await axios.post(`${baseURL}/api/token/refresh`, { refresh_token: refreshToken });

        // Store Token
        localStorage.setItem('access_token', data.token);
        localStorage.setItem('refresh_token', data.refresh_token);
        originalConfig.headers.Authorization = `Bearer ${data.token}`;

        // Retry Request with new token
        return instance(originalConfig);
      } catch (refreshError) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('access_token');

      if (token) {
        Object.assign(config.headers, { Authorization: `Bearer ${token}` });
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
