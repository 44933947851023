const urlBuilder = (url) => ({
  url,
  searchParams: new URLSearchParams(),
  appendQuery(key, value, bool = false) {
    if (value) {
      this.searchParams.append(key, value);
    }

    if (!value && bool) {
      this.searchParams.append(key, '');
    }

    return this;
  },
  removeQuery(key) {
    this.searchParams.delete(key);
    return this;
  },
  appendParams(key, value) {
    this.url = this.url.replace(key, value || '');
    return this;
  },
  changeUrl(newUrl) {
    this.url = newUrl;
    return this;
  },
  format() {
    const queries = this.searchParams.toString() ? `?${this.searchParams.toString()}` : '';
    return this.url + queries;
  },
});

export default urlBuilder;
