import Vue from 'vue';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options = {
  position: 'bottom-right',
  // You can set your default options here
};

Vue.use(Toast, options);
