import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  history: true,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '',
      component: () => import('@/layouts/full-page/full.vue'),
      children: [
        {
          path: '/',
          name: 'login',
          component: () => import('@/views/login.vue'),
          props: (route) => ({ token: route.query.token, username : route.query.username , refresh_token : route.query.refresh_token}),
        },
        {
          path: '/admin',
          name: 'login-admin',
          redirect: (to) => ({ name: 'login', query: to.query}),
        },
        {
          path: '/Prestataire/admin',
          name: 'login-prestataire',
          redirect: (to) => ({ name: 'login', query: to.query}),
        },
        {
          path: '/pages/error-404',
          name: 'error-404',
          component: () => import('@/views/error-404.vue')
        },
      ]
    },
    {
      path: '/admin',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        {
          path: 'tableau-de-bord',
          name: 'admin-dashboard',
          component: () => import('@/views/admin/dashboard.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'commandes',
          name: 'admin-orders',
          component: () => import('@/views/admin/orders/index.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'commandes/:id',
          name: 'admin-orders-id',
          props: true,
          component: () => import('@/views/admin/orders/_id.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'agenda',
          name: 'admin-diary',
          component: () => import('@/views/admin/diary.vue'),
          props: (route) => ({ idNego: route.query.idNego }),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'rendez-vous',
          props: true,
          name: 'admin-appointments',
          component: () => import('@/views/admin/appointments/index.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'rendez-vous/:id',
          props: true,
          name: 'admin-appointments-id',
          component: () => import('@/views/admin/appointments/_id.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired: true
          }
        },
        {
          path: 'demandes-annulation',
          name: 'admin-cancellation-requests',
          component: () => import('@/views/admin/cancellation-requests/index.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'demandes-annulation/:id',
          props: true,
          name: 'admin-cancellation-requests-id',
          component: () => import('@/views/admin/cancellation-requests/_id.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired: true
          }
        },
        {
          path: 'teleconseillers',
          name: 'admin-teleconsultants',
          component: () => import('@/views/admin/teleconsultants.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired: true
          }
        },
        {
          path: 'formules',
          name: 'admin-formulas',
          component: () => import('@/views/admin/formulas/index.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired: true
          }
        },
        {
          path: 'formules/ajout',
          name: 'admin-formulas-add',
          component: () => import('@/views/admin/formulas/add.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'formules/modification/:id',
          props: true,
          name: 'admin-formulas-edit-id',
          component: () => import('@/views/admin/formulas/edit/_id.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'offres',
          name: 'admin-offers',
          component: () => import('@/views/admin/offers/index.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired: true
          }
        },   {
          path: 'offres/modification/:id',
          props: true,
          name: 'admin-offers-edit-id',
          component: () => import('@/views/admin/offers/_id.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
        {
          path: 'offres/ajout',
          name: 'admin-offers-add',
          component: () => import('@/views/admin/offers/add.vue'),
          meta: {
            rule: 'ROLE_ADMIN',
            authRequired : true
          }
        },
      ],
    },
    {
      path: '',
      component: () => import('@/layouts/main/mainNego.vue'),
      children: [
        {
          path: '/commandes/ajout',
          name: 'user-orders-add',
          props: (route) => ({ type: route.query.type} , {promtion: route.query.promtion}),
          component: () => import('@/views/user/orders/create.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/passer-commande',
          name: 'user-orders-request',
          props: true,
          component: () => import('@/views/user/orders/request.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/accueil',
          name: 'user-home',
          component: () => import('@/views/user/index.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }

        },
        {
          path: '/historique',
          name: 'user-history',
          props: (route) => ({ success: route.query.success }),
          component: () => import('@/views/user/history.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/commandes/:id',
          props: true,
          name: 'user-orders-id',
          component: () => import('@/views/user/orders/_id.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/payment/return/success',
          name: 'success',
          component: () => import('@/views/payment/success.vue'),
          props: (route) => ({ success: route.query.success }),
          meta: {
            authRequired : false
          }
        },
        {
          path: '/payment/return/refused',
          name: 'refused',
          component: () => import('@/views/payment/refused.vue'),
          meta: {
            authRequired : false
          }
        },
        {
          path: '/agenda',
          name: 'user-diary',
          props: (route) => ({ success: route.query.success }),
          component: () => import('@/views/user/diary.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/rendez-vous',
          name: 'user-appointments',
          component: () => import('@/views/user/appointments/index.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired : true
          }
        },
        {
          path: '/rendez-vous/:id',
          props: true,
          name: 'user-appointments-id',
          component: () => import('@/views/user/appointments/_id.vue'),
          meta: {
            rule: 'ROLE_USER',
            authRequired: true
          }
        }
      ],
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: (to) => ({ name: 'error-404' })
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
  store.commit('TOGGLE_PROGRESS_BAR', false);
})

router.beforeEach((to, _, next) => {
  store.commit('TOGGLE_PROGRESS_BAR', true);
  const token = localStorage.getItem('access_token')
  const roles = localStorage.getItem('userRole') ? localStorage.getItem('userRole').split(',') : null

  if (!['login', 'login-admin', 'login-prestataire', 'error-404'].includes(to.name) && !token) {
    router.replace({ name: 'login' })
  }

  if (['login'].includes(to.name) && token) {
    if (roles.includes('ROLE_ADMIN')) {
      router.replace({ name: 'admin-dashboard' })
    } else if (roles.includes('ROLE_USER')) {
      router.replace({ name: 'user-home' })
    } else {
      router.replace({ name: 'error-404' })
    }
  }

  if (to.meta.rule && roles && !roles.includes(to.meta.rule)) {
    router.replace({ name: 'error-404' })
  }

  return next()
})

export default router
