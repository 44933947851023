const parseError = (error) => ({
  get message() {
    if (typeof error === 'string') {
      return error;
    }

    if (error.message) {
      return error.message;
    }

    if (error.response && error.response.message) {
      return error.response.message;
    }

    return 'Une erreur est survenue';
  },
});

export default parseError;
