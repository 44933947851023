// src/auth/index.js

import store from '@/store/store';
import router from '../router';
import axios from '../axios';

// URL and endpoint constants
const API_URL = process.env.VUE_APP_BASE_URL;
const SIGNUP_URL = `${API_URL}/users/`;
const CRM_URL = 'http://crm.proprietes-privees.com/';
export default {
  // User object will let us check authentication status
  user: {
    authenticated: false,
  },

  // Send a request to the login URL and save the returned JWT
  login: (creds) => {
    localStorage.setItem('access_token', creds.token);
    localStorage.setItem('refresh_token', creds.refresh_token);
    localStorage.setItem('email', creds.username);
    store.commit('UPDATE_USER_INFO', {
      email: creds.username,
    });
  },

  async getOrders(trigramme) {
    const response = await axios.get(`/api/commandes?trigramme=${trigramme}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    store.commit('UPDATE_USER_INFO', {
      commandes: response.data,
    });
  },
  /**
   * Une fonction qui est appelée lorsque l'utilisateur se connecte
   * Elle obtient les informations de l'utilisateur à partir de l'API
   * et les stocke dans le magasin Vuex.
   * @param {String} email - Email de l'utilisateur
   */
  async getUser(email) {
    const response = await axios.get(`${API_URL}/api/mandataries?email=${email}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    store.commit('UPDATE_USER_INFO', {
      user: response.data[0],
      userRole: response.data[0].roles,
    });
    localStorage.setItem('userRole', response.data[0].roles);
    this.getOrders(response.data[0].trigramme);
    router.push('user-home');
  },

  getUserTeleconseiller: (email) => axios.get(`${API_URL}/api/employees?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then((response) => {
      store.commit('UPDATE_USER_INFO', {
        user: response.data[0],
        userRole: response.data[0].roles,
      });
      localStorage.setItem('userRole', response.data[0].roles);
      return response.data;
    })
    .catch((error) => (error.response)),

  signup(context, creds, redirect) {
    context.$http.post(SIGNUP_URL, creds, (data) => {
      localStorage.setItem('access_token', data.access_token);
      this.user.authenticated = true;
      if (redirect) {
        router.go(redirect);
      }
    }).error((err) => {
      context.error = err;
    });
  },

  // To log out, we just need to remove the token
  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('this.user.authenticated');
    localStorage.removeItem('this.user.refresh_token');
    localStorage.removeItem('userInfo');
    this.user.authenticated = false;
    store.commit('UPDATE_USER_INFO', {
      user: {},
      displayName: null,
      commandes: {},
    });
    store.state.calendar = {};
    location.replace(CRM_URL);
  },
  checkAuth() {
    const jwt = localStorage.getItem('access_token');
    if (jwt) {
      this.user.authenticated = true;
      this.user.email = localStorage.getItem('email');
    } else {
      this.user.authenticated = false;
    }
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader() {
    return {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };
  },

};
