import Vue from 'vue';

Vue.filter('normalizeStatus', (value) => {
  switch (value) {
    case 'ALL':
      return 'Total';
    case 'CLO':
      return 'Cloturé';
    case 'EP':
      return 'En attente de paiement';
    case 'EA':
      return 'En attente';
    case 'SB':
      return 'Suspendue';
    case 'EC':
      return 'En cours';
    case 'NR':
      return 'Non renseigné';
    case 'RF':
      return 'Refusée';
    case 'CB':
      return 'Crédit boutique';
    default:
      return 'En cours';
  }
});
