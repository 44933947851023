// MAIN COLORS - VUESAX THEME COLORS
import Vue from 'vue';
import Vuesax from 'vuesax';

export const colors = {
  primary: '#ed1651',
  // success: '#28C76F',
  // danger: '#EA5455',
  // warning: '#FF9F43',
  dark: '#1E1E1E',
  gray: '#dfdfdf',
  white: '#ffffff',
  ocre: '#deae24',
  purple: '#925fa2',
  blue: 'rgb(75,173,231)',
  orange: '#d2921f',
  green: 'rgb(81, 150, 83)',
  NR: '#e44949',
  orange2: 'rgb(230 105 12)',
  blue2: 'rgb(17 210 176)',
  green2: 'rgb(127 173 22)',
  yellow2: 'rgb(230 205 6)',

};

// CONFIGS
const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)
  disableThemeTour: false, // options[Boolean] : true, false(default)
  footerType: 'static', // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: 'vertical', // options[String]  : vertical(default) / horizontal
  navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'floating', // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  rtl: false, // options[Boolean] : true, false(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: 'light', // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: 'userInfo',

  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};

Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl });

export default themeConfig;
